import React from 'react';
import Head from 'next/head';

type Props = {
  sourceCode: string;
};

// The point of the solution is that blocks with source code behavior have
// corresponding classes like:
// * "behaviour-settings__source-code-variant--default" - for blocks that
//   needs to be displayed on the default variant.
// * "behaviour-settings__source-code-variant--source-code" and
//   "behaviour-settings__source-code-variant--source-code-SOURCE-CODE" for
//   blocks that needs to be displayed on the source code variant.
// All source code variant are hidden by default in cnet/styles/styles-cnet.scss
// and if page URL contains source code parameter, the CSS rule is inverting
// here.
const SourceCodeBehavior = ({ sourceCode }: Props) => {
  return (
    <Head>
      <style
        dangerouslySetInnerHTML={{
          __html: `
.behaviour-settings__source-code-variant--default {
  display: none;
}
.behaviour-settings__source-code-variant--source-code.behaviour-settings__source-code-variant--source-code-${sourceCode} {
  display: revert;
}
`,
        }}
      />
    </Head>
  );
};
export default SourceCodeBehavior;
