// TODO: Write proper definitions for all prop types.
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import config from 'next/config';
import dynamic from 'next/dynamic';
import routerEvents from 'next-router-events';
import { useStore } from 'react-redux';
import { DONATION_FORM_STEPS } from '../utils/constants';
import PostTransformBlocks from '../utils/postTransforms.blocks';
import * as postTransformSettings from '../utils/postTransforms.settings';
import * as field from '../utils/transforms.fields';
import { handleSourceCodeInUrl } from '../utils/attribution';
import HtmlHead from '../components/01_atoms/HtmlHead';
import { convertRun } from '../components/01_atoms/Convert';
import Tooltip from '../components/01_atoms/TooltipHint';
import AccessibilitySkip from '../components/01_atoms/AccessibilitySkip';
import Alert from '../components/01_atoms/Alert';
import SourceCodeBehavior from '../components/01_atoms/SourceCodeBehavior';

import pageTypesStyles from '../styles/page-type.module.scss';

const Header = dynamic(() => import('../components/03_organisms/Header'));
const Footer = dynamic(() => import('../components/03_organisms/Footer'));
const ErrorPage = dynamic(() => import('../pages/_error'));
const ReturnToDonationPopup = dynamic(() => import('../components/01_atoms/ReturnToDonationPopup'));
const StickyNav = dynamic(() => import('../components/02_molecules/StickyNav'));
const TopBanner = dynamic(() => import('../components/02_molecules/TopBanner'));
const PagePreviewProvider = dynamic(() => import('../components/02_molecules/PagePreview'));

const {
  publicRuntimeConfig: { GTM_QUERY, GTM_ID, ROLLBAR_CLIENT_ACCESS_TOKEN },
} = config();

const CNETLayout = ({ Component, ...props }) => {
  const store = useStore();
  const postTransform = new PostTransformBlocks();

  const {
    router,
    globalSettings,
    entity,
    breadcrumbs,
    metatags,
    statusCode,
    pageType,
    pageTypeSettings,
    forceBasicHeader,
    headerTags,
    GTMDevMode,
    isPreviewMode,
    originalUrl,
    testSuite,
  } = props;

  // Reset the focus of the page so that when routing internally, a screen reader can still skip
  // to main content.
  const resetAccessibilityFocus = () => {
    document.querySelector('.accessibility-skip').focus();
  };

  const [prevPath, setPrevPath] = useState('');
  // After upgrade on Next.js to 13.5 router.asPath value behaves different.
  // Now it is not designed to be used on SSR and doesn't contain right value.
  // Therefore, we are using req.originalUrl for SSR and replace it by
  // router.asPath in use effect below in this file, at that moment the right
  // value is defined on CSR.
  // The value of req.originalUrl can't cover all cases because it is not
  // updating for Thank You pages and so on. In these case route is the same
  // and getInitialProps method is not triggered.
  const [currentPath, setCurrentPath] = useState(originalUrl);
  const isServer = typeof window === 'undefined';

  // Helper checks whether the current page is either Appeal Thank You page or
  // Appeal Check Out page.
  const getAppealPageInfo = () => {
    const pathSuffix = currentPath.replace(field.getEntityURL(entity).url, '');
    const isThankYouPage = pathSuffix.startsWith(DONATION_FORM_STEPS['thank-you'].path);
    const isCheckoutPage = pathSuffix.startsWith('/checkout');

    // Donation form as well as a few other pages use checkout layout with its
    // own header, footer, etc.
    const isCheckoutLayout =
      (isCheckoutPage && !isThankYouPage) ||
      // RG-UPGRADE page.
      (router.pathname === '/node/regular_giving_upgrades_campaign' &&
        pathSuffix !== '/thank-you') ||
      // Gift Aid short form.
      router.pathname === '/_giftaid-shortform';

    return {
      isThankYouPage,
      isCheckoutLayout,
    };
  };

  // If this is an appeal page, check if the page is a "Thank You" page or
  // has to have checkout layout.
  const { isThankYouPage, isCheckoutLayout } = getAppealPageInfo();
  const convertEnabled = globalSettings.generalSettings.convertEnabled;
  const fundraiseUpEnabled = globalSettings.generalSettings.fundraiseUpEnabled;
  const sourceCode = router.query?.source;

  useEffect(() => {
    if (convertEnabled) {
      // Trigger A/B testing changes post-hydration.
      // https://support.convert.com/hc/en-us/articles/205159975
      convertRun();
      routerEvents.on('routeChangeComplete', convertRun);
    }

    routerEvents.on('routeChangeComplete', resetAccessibilityFocus);

    // Get rid of internal _nocdncache param. We don't want users to share
    // URLs that bypass Cloudflare cache.
    if (router.query._nocdncache) {
      // Exclude _nocdncache from query object.
      const { _nocdncache, ...query } = router.query; // eslint-disable-line no-unused-vars
      const route = {
        pathname: router.pathname,
        query,
      };
      router.replace(route, router.asPath.replace(/(\?|&)_nocdncache=_nocdncache/, ''), {
        shallow: true,
      });
    }

    // Store attribution data if source code is presented in URL.
    if (sourceCode) {
      handleSourceCodeInUrl(sourceCode, entity, props.blocks);
    }

    return () => {
      if (convertEnabled) {
        routerEvents.off('routeChangeComplete', convertRun);
      }
      routerEvents.off('routeChangeComplete', resetAccessibilityFocus);
    };
  }, []);

  useEffect(() => {
    // For every new page we additionally run post transforms of its blocks
    // in order to add callbacks / functions to it.
    // We can't do it before, because all functions get lost after content
    // from node.js will be passed to the browser via JSON.Stringify function,
    // which is done by Next.js automatically.
    if (!isServer && router.asPath !== currentPath) {
      setPrevPath(currentPath);
      setCurrentPath(router.asPath);
    }
  }, [router.asPath]);

  const { footerSettings, headerSettings, topBanner, homepageLink, generalSettings } =
    globalSettings;

  const isActivismPageType = pageType === 'activism';

  const isNoNavigationPageType = pageType === 'no_navigation';

  let isBasicHeader = !isThankYouPage && forceBasicHeader;

  // Force basic Header if Header menu is empty.
  if (
    !isBasicHeader &&
    headerSettings &&
    headerSettings.navigationContents &&
    !headerSettings.navigationContents.length
  ) {
    isBasicHeader = true;
  }

  const pageTypeClasses = [
    pageType ? pageTypesStyles[`${pageType}-page-type`] : '',
    pageType && pageTypeSettings.pageTypeVariant
      ? pageTypesStyles[`${pageType}-page-type--variant-${pageTypeSettings.pageTypeVariant}`]
      : '',
  ];

  let HeaderComponent;
  let headerProps;

  // Header without menu links.
  if (
    headerSettings &&
    isBasicHeader &&
    !isCheckoutLayout &&
    !isActivismPageType &&
    !isNoNavigationPageType
  ) {
    HeaderComponent = Header;
    headerProps = {
      display: 'basic',
      tags: headerTags,
      ...postTransformSettings.header(headerSettings),
    };
  }
  // Sticky Header with menu links.
  else if (
    headerSettings &&
    !isBasicHeader &&
    !isCheckoutLayout &&
    !isActivismPageType &&
    !isNoNavigationPageType
  ) {
    HeaderComponent = StickyNav;
    headerProps = {
      display: 'full',
      ...postTransformSettings.header(headerSettings),
    };
  }
  // Activism page type header with sub-navigation.
  else if (isActivismPageType) {
    HeaderComponent = StickyNav;
    headerProps = {
      display: 'full',
      overlay: true,
      ...pageTypeSettings,
    };
  }
  // Allows making Fundraise Up live payments in test environments.
  const livePaymentInDevEnv = !!router.query.live_payment;

  const main = (
    <main className={pageTypeClasses.join(' ')}>
      <HtmlHead
        metatags={metatags}
        generalSettings={globalSettings.generalSettings}
        GTMId={GTM_ID}
        GTMDevMode={GTMDevMode}
        GTMQuery={GTM_QUERY}
        rollbarClientAccessToken={ROLLBAR_CLIENT_ACCESS_TOKEN}
        cookiebotId={generalSettings.cookiebotId}
        convertEnabled={convertEnabled}
        fundraiseUpEnabled={fundraiseUpEnabled}
        livePaymentInDevEnv={livePaymentInDevEnv}
        testSuite={testSuite}
      />
      {/* Load an additional fonts for Activism page types. */}
      {isActivismPageType && (
        <Head>
          {/* eslint-disable-next-line @next/next/no-page-custom-font */}
          <link
            href="https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@1,700&family=IBM+Plex+Sans:ital,wght@1,700&display=swap"
            rel="stylesheet"
          />
        </Head>
      )}
      {/*
       Handling source code behavior by adding corresponding CSS rules based
       on source parameter in URL. The behavior is only applied to the exact
       page that contains URL parameter, and we can't use data stored for
       attribution because it will hide both default and source code variants
       on other pages, which don't contain the variant for the source code
       currently stored.
       The "Thank you" page is handled separately in 'pages/node/appeal.js'.
       */}
      {sourceCode && !isThankYouPage && <SourceCodeBehavior sourceCode={sourceCode} />}

      <>
        <noscript className="main-noscript">
          NOTE: Please enable JavaScript to experience the full website
        </noscript>

        {headerSettings && <AccessibilitySkip />}

        {!forceBasicHeader && <ReturnToDonationPopup />}

        <Alert />

        <Tooltip />

        {headerProps && <HeaderComponent {...headerProps} />}

        {!isCheckoutLayout && !isActivismPageType && !isNoNavigationPageType && (
          <TopBanner settings={topBanner} context={{ entity }} />
        )}

        {/*
         * NOTE that we switch between server blocks and client blocks
         * to keep post-transforms on the client.
         */}
        {statusCode === 200 && (
          <Component
            isThankYouPage={isThankYouPage}
            entity={entity}
            breadcrumbs={breadcrumbs}
            store={store}
            {...props}
            blocks={postTransform.transform(props.blocks)}
            thankYouBlocks={postTransform.transform(props.thankYouBlocks)}
            thankYouBlocksRG={postTransform.transform(props.thankYouBlocksRG)}
            prevPath={prevPath}
            currentPath={currentPath}
          />
        )}

        {statusCode !== 200 && (
          <ErrorPage
            statusCode={statusCode}
            homeNextLink={homepageLink.url ? homepageLink : null}
          />
        )}

        {footerSettings && !isCheckoutLayout && <Footer data={footerSettings} />}
      </>
    </main>
  );

  if (isPreviewMode) {
    return <PagePreviewProvider>{main}</PagePreviewProvider>;
  }

  return main;
};

export default CNETLayout;
